import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/UserStore';

const routes= [
  { path: '/', component: () => import('../views/MyProfilePage.vue') },
  { path: '/myProfile', component: () => import('../views/MyProfilePage.vue') },
  { path: '/repository', component: () => import('../views/RepositoriesPage.vue') },
  {
    path: '/repository/:repositoryId',
    component: () => import('../views/RepositoryConfigurationPage.vue')
  },
  {
    path: '/repository/:repositoryId/view/:version',
    component: () => import('../views/ModelViewPage.vue')
  },
  {
    path: '/repository/:repositoryId/view/:version/element/:elementId',
    component: () => import('../views/ModelViewPage.vue')
  },
  { path: '/resource', component: () => import('../views/ResourcePage.vue') },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  if (!userStore.user){
    await userStore.fetchCurrentUser();
  }

  if (!userStore.user && to.path !== '/myProfile') {
    next('/myProfile');
  } else {
    next();
  }
});

export default router;

