import { ref, computed } from "vue";
import { defineStore } from "pinia";
import HttpService from '@/services/HTTPService'

export const useUserStore = defineStore('user', ()=>{
    const user = ref<{ email: string } | null>(null);
    const roles = ref<string[] | null>(null);

    // FIXME cannot use i18n here because userstore is accessed from the router
    // which is happening not in the setup function of a component but outside of that
    // and that is causing an error

    // const { t } = useI18n()
    // const toast = useToast()

    async function fetchCurrentUser() {
        try {
            const res = await HttpService.getAxiosClient().get(`/users/me`)
            user.value = { email: res.data.email };
            roles.value = res.data.roles;
        } catch (err) {
            console.error("Error fetching current user", err);
            //HttpService.handleAxiosError(toast, t, err)
        }
    }

    const isAdmin = computed(() => roles.value?.includes('ADMIN'));

    const isRepositoryManager = computed(() => roles.value?.includes('REPOSITORY_MANAGER'));

    const isUser = computed(() => roles.value?.includes('USER'));

    return { user, roles, fetchCurrentUser, isAdmin, isRepositoryManager, isUser }
})