<script setup lang="ts">
</script>

<template>
    <MenuHeader></MenuHeader>

    <div class="grid m-0">

        <div id="leftPanel" class="col-fixed bg-bluegray-900" style="width: 150px; padding: 0px;">
            <ActionPanel />
        </div>

        <div class="p-0 col" style="margin-left: 1rem">
            <slot></slot>
        </div>
    </div>

</template>

<style>
#leftPanel {
    height: calc(100vh - 90px);
}
</style>