import Keycloak from 'keycloak-js'
import { backendApiUrl } from '@/utilities/constants'
import axios from 'axios'

const keycloakConfig = await axios.create({ baseURL: backendApiUrl })
  .get('/config/keycloak')
  .then(response => ({
    url: response.data['auth-server-url'],
    realm: response.data['realm'],
    clientId: response.data['resource']
}));

const keycloakInstance = new Keycloak(
  keycloakConfig
)

interface CallbackOneParam<T1 = void, T2 = void> {
  (param1: T1): T2
}
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback: CallbackOneParam) => {
  keycloakInstance
    .init({ onLoad: 'login-required', checkLoginIframe: false })
    .then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert('non authenticated')
    })
    .catch((e) => {
      console.dir(e)
      console.log(`keycloak init exception: ${e}`)
    })
}

const Email = (): string | undefined => keycloakInstance?.tokenParsed?.email

const UserName = (): string | undefined => keycloakInstance?.tokenParsed?.preferred_username

const Token = (): string | undefined => keycloakInstance?.token

const LogOut = () => keycloakInstance.logout()

const UserRoles = (): string[] | undefined => {
  if (keycloakInstance.realmAccess === undefined) {
    return undefined
  }

  return keycloakInstance.realmAccess?.roles
}

const refreshToken = () => keycloakInstance.updateToken(5).catch(doLogin)

const updateToken = (successCallback: any) =>
  keycloakInstance.updateToken(5).then(successCallback).catch(doLogin)

const doLogin = keycloakInstance.login

const isLoggedIn = () => !!keycloakInstance.token

const KeyCloakService = {
  CallLogin: Login,
  GetUserName: UserName,
  GetEmail: Email,
  GetAccessToken: Token,
  CallLogout: LogOut,
  GetUserRoles: UserRoles,
  UpdateToken: updateToken,
  IsLoggedIn: isLoggedIn,
  RefreshToken: refreshToken
}

export default KeyCloakService
