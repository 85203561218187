<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
import keyCloakService from '@/services/KeyCloakService'

interface MenuItem {
  icon: string;
  label: string;
  route?: string;
  command?: () => void;
}

const { t } = useI18n();

const router = useRouter()

const userStore = useUserStore();

const menu = ref<null | any>(null);


const menuItems = ref<MenuItem[]>([]);

const toggle = (event: MouseEvent) => {
  menu.value?.toggle(event);
}

onMounted(async () => {

  await userStore.fetchCurrentUser();
  menuItems.value = [
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      label: computed(_=> t('userNavigation.profile')).value as string,
      icon: 'pi pi-user',
      command: () => {
        router.push('/myProfile');
      }
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      label: computed(_=> t('userNavigation.logout')).value as string,
      icon: 'pi pi-power-off',
      command: () => {
        keyCloakService.CallLogout();
        router.replace('/myProfile');
      }
    }
  ];
});

</script>

<template>

  <div class="grid" style="margin: 0px; padding: 0px;">

    <div class="col-fixed bg-bluegray-900"
      style="width: 150px; display: flex; align-items: center; justify-content: center;">
      <img src="/logo.png" height="40px" alt="Model server logo">
    </div>

    <div class="col" style="padding: 0px;">
      <Menubar class="border-noround bg-bluegray-900 w-full" style="padding-left: 0px;">
        <template #start>
          <div style="color: white; font-size: 30px; font-weight: bold;">Astah Model Server</div>
        </template>
        <template #end>
          <div class="flex flex-column" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
            <Menu ref="menu" id="overlay_menu" :model="menuItems" :popup="true" />

            <div class="flex justify-content-center align-items-center gap-2">
              <Avatar image="https://avatar.iran.liara.run/public/boy?username=Ash" shape="circle" size="large" />
            </div>

            <div class="flex justify-content-center align-items-center gap-2 ml-2" style="color: white;">
              <div v-if="userStore.user" style="font-weight: bold;">{{ userStore.user.email }}</div>
            </div>
          </div>
        </template>
      </Menubar>
    </div>

  </div>

</template>

<style scoped>
.p-menubar {
  height: 70px;
}
</style>