import './assets/main.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeflex/primeflex.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'

import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'

import KeyCloakService from './services/KeyCloakService'
import HttpService from './services/HTTPService'

import ToastService from 'primevue/toastservice'
import DialogService from 'primevue/dialogservice'
import ConfirmationService from 'primevue/confirmationservice'

import MainLayout from './layouts/MainLayout.vue'
import BreadcrumbLayout from './layouts/BreadcrumbLayout.vue'
import MenuHeader from './layouts/MenuHeader.vue'
import ActionPanel from './layouts/ActionPanel.vue'
import Toast from 'primevue/toast';
import ConfirmDialog from "primevue/confirmdialog";

import { languages, defaultLocale } from './translations'

const renderApp = () => {

    const app = createApp(App)
    app.use(router)
    app.use(createPinia())

    const i18n = createI18n({
        legacy: false,
        locale: defaultLocale,
        fallbackLocale: 'en_GB',
        messages: languages,
    })

    app.use(PrimeVue)
    app.use(i18n)
    app.use(ToastService)
    app.use(ConfirmationService)
    app.use(DialogService)

    app.component('MainLayout', MainLayout)
    app.component('MenuHeader', MenuHeader)
    app.component('ActionPanel', ActionPanel)
    app.component('BreadcrumbLayout', BreadcrumbLayout)
    // eslint-disable-next-line vue/multi-word-component-names
    app.component('Toast', Toast)
    app.component('ConfirmDialog', ConfirmDialog)

    app.mount('#app')
}

// https://github.com/achernetsov/vue-keycloak-template
// https://blog.devgenius.io/security-in-vuejs-3-0-with-authentication-and-authorization-by-keycloak-part-1-ae884889fa0d

KeyCloakService.CallLogin(renderApp)
HttpService.configureAxiosKeycloak()
