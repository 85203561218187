import en_GB from './en-GB.json'
import hu_HU from './hu-HU.json'
import ja_JP from './ja-JP.json'

function replaceHyphenWithUnderscore(lang: string) {
    return lang.replace('-', '_');
}

const lang = navigator.language;
const formattedLang = replaceHyphenWithUnderscore(lang);
export const defaultLocale = formattedLang;

export const languages = {
    en_US: en_GB,
    en_GB: en_GB,
    hu_HU: hu_HU,
    ja_JP: ja_JP
}