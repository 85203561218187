import axios, { AxiosError, type AxiosInstance, type InternalAxiosRequestConfig } from 'axios'
import KeyCloakService from './KeyCloakService'
import { backendApiUrl } from '@/utilities/constants'
import type { ComposerTranslation } from 'vue-i18n'
import type { ToastServiceMethods } from 'primevue/toastservice'

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH'
}

interface StandardError {
  statusCode: string;
}

interface ValidationError {
  title: string;
  status: number;
  violations: Array<{ field: string; message: string }>;
}

const statusCodeToTranslation = (t: ComposerTranslation, statusCode: string): string => {
  const translation = t('components.toastMessages.backendErrors.'+statusCode)
  return translation ? translation : t('components.toastMessages.backendErrors.INTERNAL_SERVER_ERROR')
}

const handleAxiosError = (toast: ToastServiceMethods, t: ComposerTranslation, error: AxiosError) => {
  if (error.response) {
    const data = error.response.data as StandardError | ValidationError;

    if ('statusCode' in data) {
      toast.add({
        severity: 'error',
        summary: t('components.toastMessages.summaries.error'),
        detail: statusCodeToTranslation(t, data.statusCode),
        life: 3000
      });
    } else if ('violations' in data) {
      toast.add({
        severity: 'error',
        summary: t('components.toastMessages.summaries.error'),
        detail: statusCodeToTranslation(t, 'VALIDATION_ERROR'),
        life: 3000
      });
    }
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
}

const _axios = axios.create({
  baseURL: backendApiUrl
})

const cb = (config: InternalAxiosRequestConfig) => {
  config.headers.Authorization = `Bearer ${KeyCloakService.GetAccessToken()}`
  return config
}

const configureAxiosKeycloak = (): void => {
  _axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      if (KeyCloakService.IsLoggedIn()) {
        KeyCloakService.UpdateToken(cb(config))
      }
      return config
    }
  )
}

const getAxiosClient = (): AxiosInstance => _axios

const HttpService = {
  HttpMethods,
  configureAxiosKeycloak,
  getAxiosClient,
  handleAxiosError
}

export default HttpService
