<script setup lang="ts">
import { useUserStore } from '@/stores/UserStore';
import { useI18n } from 'vue-i18n'
import {computed} from "vue";

const { t } = useI18n();
const userStore = useUserStore();
</script>

<template>

    <div class="mt-3">
        <ul class="m-0 p-3 list-none">

            <li>
                <router-link to="/myProfile">
                    <Button class="hover:bg-primary-500 mb-3 w-full text-gray-300 hover:text-white" icon="pi pi-id-card"
                        size="small" iconPos="top" :label="computed(_ => t('actionPanel.myProfile')).value as string" text>
                    </Button>
                </router-link>
            </li>

            <li v-if="userStore.isAdmin">
                <router-link to="/repositoryManager">
                    <Button class="hover:bg-primary-500 mb-3 w-full text-gray-300 hover:text-white" icon="pi pi-user"
                        size="small" iconPos="top" :label="computed(_ => t('actionPanel.repositoryManagers')).value as string" text>
                    </Button>
                </router-link>
            </li>

            <li v-if="userStore.isRepositoryManager || userStore.isUser || userStore.isAdmin">
                <router-link to="/repository">
                    <Button class="hover:bg-primary-500 mb-3 w-full text-gray-300 hover:text-white"
                        icon="pi pi-database" size="small" iconPos="top" :label="computed(_ => t('actionPanel.repositories')).value as string" text>
                    </Button>
                </router-link>
            </li>

          <li v-if="userStore.isRepositoryManager || userStore.isUser || userStore.isAdmin">
            <router-link to="/resource">
              <Button class="hover:bg-primary-500 mb-3 w-full text-gray-300 hover:text-white"
                      icon="pi pi-file" size="small" iconPos="top" :label="computed(_ => t('actionPanel.resources')).value as string" text>
              </Button>
            </router-link>
          </li>



        </ul>
    </div>

</template>
